import {
  Button,
  ButtonTypes,
  FormTextField,
  IError,
  IProfile,
  LoadingOverlay,
  Spinner,
  TextFieldTypes,
  useToast,
} from '@comptia-sso/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import {
  useAuthLogout,
  useChangeEmail,
  useSendEmailVerificationCode,
  useResendEmailVerificationCode,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Styles.
import styles from './EditEmail.module.scss';

// Validations.
import {
  getSendEmailVerificationValidationSchema,
  getChangeEmailValidationSchema,
} from './validationSchema';

interface IEditEmailProps {
  profile: IProfile;
}

export const EditEmail = (props: IEditEmailProps): ReactElement => {
  const { profile } = props;
  const trackEvent = useTrackEvent('Edit Email');
  const trackOdpEvent = useTrackOdpEvent();
  const [popToast] = useToast();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<string>('SendEmailVerificationCode');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState<boolean>(false);
  const logout = useAuthLogout();
  const [changeEmail] = useChangeEmail(profile?.ssoId);
  const [sendEmailVerificationCode] = useSendEmailVerificationCode(
    profile?.ssoId,
  );
  const [resendNewVerificationCode] = useResendEmailVerificationCode(
    profile?.ssoId,
  );

  const resendNewVerificationCodeClick = async () => {
    try {
      setIsResendingCode(true);
      await resendNewVerificationCode(null);
      setIsResendingCode(false);
    } catch (error) {
      setIsResendingCode(false);
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  };

  const formikSendVerification = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      email: profile?.email ?? '',
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        trackEvent('Email Verification Sent');
        await sendEmailVerificationCode(values);
        popToast(t('Toast.Success.VerificationCodeSent'));
        setFormMode('ChangeEmail');
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
      setIsSubmitting(false);
    },
    validateOnChange: false,
    validationSchema: getSendEmailVerificationValidationSchema(t, profile),
  });

  const formikChangeEmail = useFormik({
    enableReinitialize: true,
    initialValues: {
      verificationCode: '',
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        trackEvent('Submitted');
        trackOdpEvent('account', 'update email', '');
        await changeEmail(values);
        setIsChanged(true);
        popToast(t('Toast.Success.EditEmail'), {
          action: () => <Spinner />,
          persist: true,
        });
        setTimeout(() => logout(window.location.href), 3000);
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
      setIsSubmitting(false);
    },
    validateOnChange: false,
    validationSchema: getChangeEmailValidationSchema(t),
  });

  useEffect(() => {
    if (!isEditing) {
      setFormMode('SendEmailVerificationCode');
      formikChangeEmail.resetForm();
      formikSendVerification.resetForm();
    }
  }, [isEditing]);

  if (profile?.authenticationSource === 'socialIdpAuthentication') {
    return (
      <div className={styles.social}>
        <strong className={styles.heading}>
          {t('EditAccount.Email.Heading')}
        </strong>
        <span className={styles.text}>{t('EditAccount.Email.Social')}</span>
      </div>
    );
  }

  return (
    <>
      <small className={styles.required}>{t('EditAccount.Required')}</small>
      {formMode === 'SendEmailVerificationCode' && (
        <form
          aria-label="send verification email"
          noValidate
          onSubmit={formikSendVerification.handleSubmit}
        >
          <FormikProvider value={formikSendVerification}>
            <fieldset>
              <legend>
                {t('EditAccount.Email.Heading')}
                {isEditing ? (
                  <button
                    aria-label="disable editing"
                    className={`${styles.edit} ${styles.cancel}`}
                    onClick={() => setIsEditing(false)}
                    type="button"
                  >
                    {t('Button.Cancel')}
                  </button>
                ) : (
                  <button
                    aria-label="enable editing"
                    className={styles.edit}
                    onClick={() => setIsEditing(true)}
                    type="button"
                  >
                    {t('Button.Edit')}
                  </button>
                )}
              </legend>
              <div className={styles.grid}>
                <FormTextField
                  disabled={!isEditing || isChanged}
                  label={t('EditAccount.Email.EmailAddress')}
                  maxLength={255}
                  name="email"
                  required
                  type={TextFieldTypes.Email}
                />
                <FormTextField
                  disabled={!isEditing || isChanged}
                  endIcon={
                    isCurrentPasswordVisible ? (
                      <button
                        aria-label="hide password"
                        disabled={!isEditing}
                        onClick={() => setIsCurrentPasswordVisible(false)}
                        type="button"
                      >
                        <Visibility />
                      </button>
                    ) : (
                      <button
                        aria-label="show password"
                        disabled={!isEditing}
                        onClick={() => setIsCurrentPasswordVisible(true)}
                        type="button"
                      >
                        <VisibilityOff />
                      </button>
                    )
                  }
                  label={t('EditAccount.Email.ConfirmCurrentPassword')}
                  name="currentPassword"
                  required
                  type={
                    isCurrentPasswordVisible
                      ? TextFieldTypes.Text
                      : TextFieldTypes.Password
                  }
                />
              </div>
            </fieldset>
            <strong className={styles.info}>
              {t('EditAccount.Email.VerificationInstructions')}
            </strong>
            <Button
              className={styles.button}
              disabled={!isEditing || isChanged}
              type={ButtonTypes.Submit}
            >
              {t('EditAccount.Email.SendVerificationCode')}
            </Button>
          </FormikProvider>
          <LoadingOverlay
            isOpen={isSubmitting}
            text={t('Loading.SendingVerificationCode')}
          />
        </form>
      )}
      {formMode === 'ChangeEmail' && (
        <form
          aria-label="verify code and edit email"
          noValidate
          onSubmit={formikChangeEmail.handleSubmit}
        >
          <FormikProvider value={formikChangeEmail}>
            <fieldset>
              <legend>
                {t('EditAccount.Email.VerifyCode')}
                {isEditing ? (
                  <button
                    aria-label="disable editing"
                    className={`${styles.edit} ${styles.cancel}`}
                    onClick={() => setIsEditing(false)}
                    type="button"
                  >
                    {t('Button.Cancel')}
                  </button>
                ) : (
                  <button
                    aria-label="enable editing"
                    className={styles.edit}
                    onClick={() => setIsEditing(true)}
                    type="button"
                  >
                    {t('Button.Edit')}
                  </button>
                )}
              </legend>
              <div className={styles.grid}>
                <FormTextField
                  disabled={!isEditing || isChanged}
                  label={t('EditAccount.Email.VerificationCode')}
                  maxLength={255}
                  name="verificationCode"
                  required
                  type={TextFieldTypes.Text}
                />
              </div>
            </fieldset>
            <strong className={styles.info}>
              {t('EditAccount.Email.Disclaimer')}
            </strong>
            <Button
              className={styles.button}
              disabled={!isEditing || isChanged}
              type={ButtonTypes.Submit}
            >
              {t('EditAccount.Email.Submit')}
            </Button>
            <Button
              className={styles.button}
              disabled={!isEditing || isChanged}
              type={ButtonTypes.Button}
              onClick={resendNewVerificationCodeClick}
            >
              {t('EditAccount.Email.ResendVerificationCode')}
            </Button>
          </FormikProvider>
          <LoadingOverlay
            isOpen={isSubmitting}
            text={t('Loading.UpdatingEmail')}
          />
          <LoadingOverlay
            isOpen={isResendingCode}
            text={t('Loading.ResendingVerificationCode')}
          />
        </form>
      )}
    </>
  );
};
